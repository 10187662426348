import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "remixicon/fonts/remixicon.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Auth from './pages/public/Auth';
import DashBoard from './pages/private/DashBoard';
import Orders from './pages/private/Orders';
import Shippers from './pages/private/Shippers';
import NotFound from './pages/NotFound';
import Users from './pages/private/Users';
import Companies from './pages/private/Companies';
import SearchPage from './pages/private/SearchPage';
import SearchTest from './pages/private/SearchTest';
import Test from './pages/private/Test';
// import "react-datepicker/dist/react-datepicker.css";
import 'rsuite/DatePicker/styles/index.css';
import 'rsuite/InputPicker/styles/index.css';
import 'rsuite/Tag/styles/index.css';
import 'rsuite/CheckPicker/styles/index.css';
import PermissionManager from './pages/private/PermissionManager';
import Order from './pages/private/Order';
import BillOfLading from './pages/private/BillOfLading';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
    
      {
        path: "commandes",
        element: (
          <React.Fragment>
              <Order />
          </React.Fragment>
        ),
      },
      {
        path: "commande",
        element: (
          <React.Fragment>
              <Orders />
          </React.Fragment>
        ),
      },
      {
        path: "connaissements",
        element: (
          <React.Fragment>
              <BillOfLading />
          </React.Fragment>
        ),
      },
      {
        path: "compagnies",
        element: (
          <React.Fragment>
              <Companies />
          </React.Fragment>
        ),
      },
      {
        path: "utilisateurs",
        element: (
          <React.Fragment>
              <Users />
          </React.Fragment>
        ),
      },
      {
        path: "permissions",
        element: (
          <React.Fragment>
              <PermissionManager />
          </React.Fragment>
        ),
      },
     
    ],
  },
  {
    path: "/connexion",
    element: <Auth />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);



serviceWorkerRegistration.register();

reportWebVitals();
