import { Form, InputGroup, Table, Row, Col, Button, Modal, Container, ToastContainer, Toast, Alert } from 'react-bootstrap'


export  function ToastCurrentTrip(toastCurrentTripProps: any) {
    const {showA, toggleShowA} = toastCurrentTripProps;
  return (
    <ToastContainer position='top-end' className='mt-2 me-2' style={{ zIndex: 1500000 }}>
        <Toast show={showA} onClose={toggleShowA} delay={5000} autohide>
          <Alert
            variant='danger'
            className='p-3 mb-0 d-flex justify-content-start align-items-center'
          >
            <i className='ri-close-circle-line text-danger fs-3 me-2'></i>{' '}
            <strong className=''>Ce voyage est en cours, choisissez en un autre !</strong>
          </Alert>
        </Toast>
      </ToastContainer>
  )
}

export  function ToastSendedBrouillon({toastSendedBrouillonProps}: any) {
    const {showBrouillon, toggleShowBrouillon} = toastSendedBrouillonProps;
  return (
    <ToastContainer position='top-end' className='mt-2 me-2' style={{ zIndex: 1500000 }}>
        <Toast show={showBrouillon} onClose={toggleShowBrouillon} delay={5000} autohide>
          <Alert
            variant='success'
            className='p-3 mb-0 d-flex justify-content-start align-items-center'
          >
            <i className='ri-checkbox-circle-line text-success fs-3 me-2'></i>{' '}
            <strong className=''>Brouillon créé avec Success !</strong>
          </Alert>
        </Toast>
      </ToastContainer>
  )
}

export function ToastUpdateUserSuccess({toastUpdateUserSuccessProps}: any) {
const {showUpdateSuccess, toggleShowUpdateSuccess} = toastUpdateUserSuccessProps;
  return (
    <ToastContainer containerPosition='fixed' position='top-end' className='mt-2 me-2' style={{ zIndex: 1500000 }}>
        <Toast
          show={showUpdateSuccess}
          onClose={toggleShowUpdateSuccess}
            delay={3000} autohide
        >
          <Alert  
            variant='success'
            className='p-3 mb-0 d-flex justify-content-start align-items-center'
          >
            <i className='ri-checkbox-circle-line text-success fs-3 me-2'></i>{' '}
            <strong className=''>Modification effectuée avec succès !</strong>
          </Alert>
        </Toast>
      </ToastContainer>
  )
}
