import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'

export default function NotFound() {
  return (
      <div className='d-flex justify-content-center align-items-center vh-100 text-muted p-1 p-lg-3'>
        <Row>
          <Col xs={12} md={12} className='text-center py-3'>
            404 Error - Not Found
            <br />
          </Col>
          <Col xs={12} md={12} className='text-center'>
            <Button variant='outline-secondary' href='/'>
              Go Home
            </Button>
          </Col>
        </Row>
      </div>
  )
}
