

import {create} from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export type UserState = {
  id: string | null;
  name: string | null;
  roles: any | null;
  email: string | null;
  token: string | null;
  company: any | null;
  id_company: number | null;
  name_company: string | object | null;
  slug_company: any | null;
  access_token :string | null;
  authLogin: any;
  authLogout: () => void;
};

const userStore = create(
  persist<UserState>(
    (set) => ({
      // initial state
      id: null,
      name: null,
      roles: null,
      email: null,
      token: null,
      company: null,
      id_company: null,
      name_company: null,
      slug_company: null,
      access_token: null,

      // methods for manipulating state
      authLogin: (
        id: string | null,
        name: string | null,
        roles: any | null,
        email: string | null,
        token: string | null,
        company: any | null,
        id_company: number | null,
        name_company: string | object | null,
        slug_company: string | null,
        access_token: string|null,
      ) =>
        set((state: any) => ({
          id: id,
          name: name,
          roles: roles,
          email: email,
          token: token,
          company: company,
          id_company: id_company,
          name_company: name_company,
          slug_company: slug_company,
          access_token: access_token,
        })),
      authLogout: () =>
        set((state: any) => ({
          id: null,
          name: null,
          roles: null,
          email: null,
          token: null,
          company: null,
          id_company: null,
          name_company: null,
          slug_company: null,
          access_token: null,
        })),
    }),
    {
      name: "userLog", // unique name
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage sessionStorage' is used
    }
  )
);

export default userStore;