import { statusType } from "../definitions/statusType"
import { stockageType } from "../definitions/stockageType"
import pontee from '../styles/images/pontee.png'
import calex from '../styles/images/calex.png'
import fresh from '../styles/images/fresh.png'
import freeze from '../styles/images/freeze.png'
import OrdersService from "../services/orders/OrdersService"
import moment from "moment"


export const _handleSelectOrders = (order: any, ordersForConnaissement: any[], setOrdersForConnaissement: React.Dispatch<React.SetStateAction<any[]>>) => {
    if (ordersForConnaissement.includes(order)) {
      setOrdersForConnaissement(ordersForConnaissement.filter((item: any) => item !== order))
    } else {
      setOrdersForConnaissement([...ordersForConnaissement, order])
    }
  }

  // Fonction qui permet de formater la date du jour (YY-mm-dd)
  export const _todayDate = () => {
    const today = new Date()

    // Obtenir l'année
    const year = today.getFullYear()

    // Obtenir le mois (ajouter 1 car les mois commencent à 0)
    const month = String(today.getMonth() + 1).padStart(2, '0')

    // Obtenir le jour du mois
    const day = String(today.getDate()).padStart(2, '0')

    // Combiner le tout dans le format souhaité
    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
  }

  export const _formatDate = (date: string) => {
    const formattedDate = moment(date).format('DD/MM/YYYY');

    return formattedDate;

  }

  //Fonction concatène le numéro de plusienurs factures
  export const _numFacture = (ordersForConnaissement: any) => {
    if (ordersForConnaissement?.length > 0) {
      const concateNumFacture = ordersForConnaissement.map((order: any) => order.referenceHorsRevatua).join('|')
      return concateNumFacture
    }
  }
  //Fonction récupère l'id de plusienurs factures
  export const _idOrders = (ordersForConnaissement: any) => {
    if (ordersForConnaissement?.length > 0) {
      const concateIdFacture = ordersForConnaissement.map((order: any) => order.id)
      return concateIdFacture
    }
  }

  //Fonction concatène les produits de plusienurs factures et formatte les données
 export const _detailProduct = (ordersForConnaissement: any) => {
    if (ordersForConnaissement?.length > 0) {
      const formatData = (data: any) => {
        return data.map((item: any) => ({
          // id_company: item.id_company,
          // id_order: item.id_order,
          // id: item.id,
          nbColis: item.detail_nbColis,
          description: item.detail_description.trim(),
          codeSH: item.detail_codeSH,
          codeTarif: item.detail_codeTarif,
          stockage: item.detail_stockage,
          poids: item.detail_poids,
          unitePoids: item.detail_unitePoids,
          referenceExterne: item.detail_referenceExterne
        }));
      };
      const detailProducts = ordersForConnaissement.map((order: any) => 
        order.items.map((detail: any) => detail)
      );
      // Utiliser reduce pour concaténer tous les tableaux dans `detailProducts`
      const concatdetailProduct = detailProducts.reduce((acc: any[], current: any[]) => acc.concat(current), []);
      return formatData(concatdetailProduct);

    }
  }

  export const _totalColis = (ordersForConnaissement: any) => {
    const detailProducts = ordersForConnaissement?.map((order: any) => 
      order.items.map((detail: any) => detail)
    )[0];
    // Utiliser reduce pour concaténer tous les tableaux dans `detailProducts`
    const total = detailProducts?.reduce((acc: any, current: any) => 
      acc + current.detail_nbColis
    , 0);
    return total;
  }

  //Définie la couleur des tags en fonction du status
  export const _tagStatus = (status: statusType) => {
      switch (status) {

        case 'BROUILLON': return 'yellow';

        case 'DEMANDE': return 'cyan';

        case 'REFUS_DEMANDE': return 'red';
        
        case 'SAISIE': return 'blue';

        case 'OFFICIALISE': return 'violet';

        case 'OFFICIALISE_SOUS_RESERVE': return 'cyan';

        case 'MODIFIE': return 'yellow';

        case 'PRISE_EN_CHARGE': return 'orange';
        
        case 'EMBARQUE': return 'green';
        
        case 'EMBARQUEMENT_REFUSE': return 'red';
        
        case 'TRANSFERE': return 'violet';
        
        case 'ANNULE': return 'red';
        
        
        default:
          return undefined;
      }
  }

  //Définie la couleur des tags en fonction du stockage
  export const _tagStockage = (stockage: stockageType) => {
    switch (stockage) {
      case 'REFRIGERE':
        return 'cyan'

      case 'CALE':
        return 'orange'

      case 'CONGELE':
        return 'blue'

      case 'PONTEE':
        return 'yellow'

      default:
        return undefined
    }
  }

  //Définie l'icone en fonction du stockage
  export const _stockagePics = (stockage: stockageType) => {
    switch (stockage) {
      case 'REFRIGERE':
        return fresh

      case 'CALE':
        return calex

      case 'CONGELE':
        return freeze

      case 'PONTEE':
        return pontee

      default:
        return undefined
    }

  }


  export const _transformDataToNested = (data: any) => {
    const result: any = []
  
    data?.forEach((item: any) => {

        result.push({
          expediteur: {
            denomination: item.expediteur_denomination,
            telephone: item.expediteur_telephone,
            mail: item.expediteur_mail,
            numeroTahiti: item.expediteur_numeroTahiti,
          },

          id: item.id,
          id_connaissement: item.id_connaissement,
          id_company: item.id_company,
          numeroVoyage: item.numeroVoyage,
          paiement: item.paiement,
          ileDepart: item.ileDepart,
          ileArrivee: item.ileArrivee,
          lieuArrivee: item.lieuArrivee,
          date_etl: item.date_etl,
          date_creation: item.date_creation,
          dateModif: item.dateModif,
          statusRevatua: item.statut_revatua,
          referenceHorsRevatua: item.referenceHorsRevatua,
          destinataire: {
            denomination: item.destinataire_denomination,
            telephone: item.destinataire_telephone,
            mail: item.destinataire_mail,
            numeroTahiti: item.destinataire_numeroTahiti,
          },
          items: item.items,
        })
    })
  
    return result
  }

  export const _transformDataToNested2 = (data: any) => {
    const result: any = []
  
    // data?.forEach((item: any) => {
    //   // Trouver s'il existe déjà un item correspondant à la même facture dans le résultat
    //   let existingItem = result.find(
    //     (resItem: any) => resItem.referenceHorsRevatua === item.referenceHorsRevatua
    //   )
  
    //   const detailItem = {
    //     id: item.id,
    //     codeSH: item.detail_codeSH,
    //     codeTarif: item.detail_codeTarif,
    //     description: item.detail_description,
    //     nbColis: item.detail_nbColis,
    //     poids: item.detail_poids,
    //     stockage: item.detail_stockage,
    //     unitePoids: item.detail_unitePoids,
    //     referenceExterne: item.detail_referenceExterne,
    //   }
  
    //   // if (existingItem) {
    //   //   // Ajouter les détails de l'article au tableau detailConnaissementDTO de l'item existant
    //   //   existingItem.items.push(detailItem)
    //   // } else {
    //     // Sinon, créer un nouvel item avec ce détail
    //     result.push({
    //       expediteur: {
    //         denomination: item.expediteur_denomination,
    //         telephone: item.expediteur_telephone,
    //         mail: item.expediteur_mail,
    //         numeroTahiti: item.expediteur_numeroTahiti,
    //       },
    //       id_connaissement: item.id_connaissement,
    //       id_company: item.id_company,
    //       numeroVoyage: item.numeroVoyage,
    //       paiement: item.paiement,
    //       ileDepart: item.ileDepart,
    //       ileArrivee: item.ileArrivee,
    //       lieuArrivee: item.lieuArrivee,
    //       date_etl: item.date_etl,
    //       date_creation: item.date_creation,
    //       dateModif: item.dateModif,
    //       statusRevatua: item.statut_revatua,
    //       referenceHorsRevatua: item.referenceHorsRevatua,
    //       destinataire: {
    //         denomination: item.destinataire_denomination,
    //         telephone: item.destinataire_telephone,
    //         mail: item.destinataire_mail,
    //         numeroTahiti: item.destinataire_numeroTahiti,
    //       },
    //       items: item.item,
    //     })
    //   // }
    // })
    data?.forEach((item: any) => {
      // Trouver s'il existe déjà un item correspondant à la même facture dans le résultat
      let existingItem = result.find(
        (resItem: any) => resItem.referenceHorsRevatua === item.referenceHorsRevatua
      )
  
      const detailItem = {
        id: item.id,
        codeSH: item.detail_codeSH,
        codeTarif: item.detail_codeTarif,
        description: item.detail_description,
        nbColis: item.detail_nbColis,
        poids: item.detail_poids,
        stockage: item.detail_stockage,
        unitePoids: item.detail_unitePoids,
        referenceExterne: item.detail_referenceExterne,
      }
  
      if (existingItem) {
        // Ajouter les détails de l'article au tableau detailConnaissementDTO de l'item existant
        existingItem.items.push(detailItem)
      } else {
        // Sinon, créer un nouvel item avec ce détail
        result.push({
          expediteur: {
            denomination: item.expediteur_denomination,
            telephone: item.expediteur_telephone,
            mail: item.expediteur_mail,
            numeroTahiti: item.expediteur_numeroTahiti,
          },
          id_connaissement: item.id_connaissement,
          id_company: item.id_company,
          numeroVoyage: item.numeroVoyage,
          paiement: item.paiement,
          ileDepart: item.ileDepart,
          ileArrivee: item.ileArrivee,
          lieuArrivee: item.lieuArrivee,
          date_etl: item.date_etl,
          date_creation: item.date_creation,
          dateModif: item.dateModif,
          statusRevatua: item.statut_revatua,
          referenceHorsRevatua: item.referenceHorsRevatua,
          destinataire: {
            denomination: item.destinataire_denomination,
            telephone: item.destinataire_telephone,
            mail: item.destinataire_mail,
            numeroTahiti: item.destinataire_numeroTahiti,
          },
          items: detailItem,
        })
      }
    })
  
    return result
  }

  export const _getOrdersData = async (token: string, setDataOrder: any, setIsLoading: any, setErrorOrderMessage: any) => {
    setIsLoading(true)
    try{

      let page = 1
      let allOrders: any = []
      let hasMorePages = true

      while (hasMorePages) {
        const response = await OrdersService.getOrders(token, page)
        allOrders = [...allOrders, ..._transformDataToNested(response.data.data)]
        // allOrders = [...allOrders, ...response.data.data]
        console.log(response.data)
        if (response.data.meta.current_page < 1) {
          // if (response.data.meta.current_page < response.data.meta.last_page) {
          page++
        } else {
          hasMorePages = false
        }
      }
      
      
      setDataOrder(allOrders)
      setIsLoading(false)
        setErrorOrderMessage({
          message: "",
          isError: false,
        })

    }catch(error: any){
        setIsLoading(false)
        setErrorOrderMessage({
          message: error?.message,
          isError: true,
        })
        console.log(error)
    }
  }

  export const _getOrdersData2 = async (
    token: string,
    page: number,  // Ajouter la page en paramètre
    setDataOrder: any,
    setTotalPages: any,
    setIsLoading: any,
    setErrorOrderMessage: any
  ) => {
    setIsLoading(true);
    try {
      const response = await OrdersService.getOrders(token, page);
  
      setDataOrder(_transformDataToNested(response.data.data));
      setTotalPages(response.data.meta.last_page);  // Nombre total de pages
      setIsLoading(false);
      setErrorOrderMessage({
        message: "",
        isError: false,
      });
    } catch (error: any) {
      setIsLoading(false);
      setErrorOrderMessage({
        message: error?.message,
        isError: true,
      });
      console.log(error);
    }
  };

  export const _handleClearCache = () => {
    if ("caches" in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
      console.log('Cache vidé')
    } else {
      console.log(
        "Impossible de vider le cache. Votre navigateur ne prend pas en charge cette fonctionnalité."
      );
    }
  };