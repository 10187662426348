import axios from "axios";

const API_URL = process.env.REACT_APP_END_POINT
class IlesService {
// https://www.revatua.gov.pf/api/v1/public/trajets?idNavire=1&page=0&limit=10&sort=dateDepart,heureDepart,asc&dateDebut=2024-08-08&dateFin=2024-08-31

    getIslandByName(name: string) {
        return axios.get(API_URL + "public/iles?critere=" + name)
    }
    getIslandVoyage() {
        return axios.get(API_URL + "public/iles/desservies-voyages?dateDebut=2024-08-12&dateFin=2024-08-31")
    }
   
  
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new IlesService();