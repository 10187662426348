import axios from "axios";


const API_URL_TOTARA = process.env.REACT_APP_REMORA_END_POINT

class UserService {
  getMe(token: string) {
    return axios.get(API_URL_TOTARA + '/api/v1/me', {
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
    })
  }

  getUsers(token: string, page: number) {
    return axios.get(API_URL_TOTARA + '/api/v1/users/company?page=' + page, {
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
    })
  }

  addUsers(token: string, data: any) {
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: API_URL_TOTARA + '/api/v1/register',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json', 
        'Authorization': 'Bearer ' + token
      },
      data : data
    };

  return axios.request(config)
    // return axios.post(API_URL_TOTARA + '/api/v1/register', data)
  }

  updateUsers(token: string, data: any, userId: string) {

    let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: API_URL_TOTARA + '/api/v1/users/' + userId,
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json', 
          'Authorization': 'Bearer ' + token
        },
        data : data
      };

    return axios.request(config)
  }
  
  deleteUsers(token: string, userId: number) {

    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: API_URL_TOTARA + '/api/v1/users/' + userId,
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json', 
          'Authorization': 'Bearer ' + token
        }
      };

    return axios.request(config)
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new UserService();