import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import userStore from '../../stores/userStore'

export default function SideMenu(sideMenuProps: any) {
  const {} = sideMenuProps
  const dataStore = userStore((state: any) => state)

  const isAdmin = dataStore?.roles && dataStore.roles[0]?.name === 'admin'
  const isSuperAdmin = dataStore?.roles && dataStore.roles[0]?.name ==='super_admin'
  return (
    <div className='p-abso bg-body-tertiary border-end pt-2'>
      <Container className='py-3'>
        <Link className='text-decoration-none ' to='/commandes'>
          <Row className=' menu-link text-center'>
            <Col xs={12} lg={2} className=''>
              {' '}
              <i className='ri-file-text-line fs-5'></i>
            </Col>{' '}
            <Col className='m-auto d-none d-sm-block text-lg-start'>Commandes</Col>
          </Row>
        </Link>
      </Container>
      <Container className='py-3'>
        <Link className='text-decoration-none ' to='/connaissements'>
          <Row className=' menu-link text-center'>
            <Col xs={12} lg={2} className='m-auto'>
              {' '}
              <i className='ri-sailboat-line fs-5'></i>
            </Col>{' '}
            <Col className='m-auto d-none d-sm-block text-lg-start responsive-font-small ps-xl-0'>
              Connaissements
            </Col>
          </Row>
        </Link>
      </Container>
      {(isAdmin || isSuperAdmin) && (
        <>
          <Container className='py-3'>
            <Link className='text-decoration-none ' to='/compagnies'>
              <Row className=' menu-link text-center'>
                <Col xs={12} lg={2}>
                  {' '}
                  <i className='ri-community-line fs-5'></i>
                </Col>{' '}
                <Col className='m-auto d-none d-sm-block text-lg-start'>Compagnies</Col>
              </Row>
            </Link>
          </Container>
          <Container className='py-3'>
            <Link className='text-decoration-none ' to='/utilisateurs'>
              <Row className=' menu-link text-center'>
                <Col xs={12} lg={2}>
                  {' '}
                  <i className='ri-user-line fs-5'></i>
                </Col>{' '}
                <Col className='m-auto d-none d-sm-block text-lg-start'>Utilisateurs</Col>
              </Row>
            </Link>
          </Container>
        </>
      )}
      {/* TODO: ajouter les permissions dans le menu
      <Container className='py-3'>
        <Link className='text-decoration-none ' to='/permissions'>
          <Row className=' menu-link text-center'>
            <Col xs={12} lg={2}>
              {' '}
              <i className='ri-list-check-3 fs-5'></i>
            </Col>{' '}
            <Col className='m-auto d-none d-sm-block text-lg-start'>Permissions</Col>
          </Row>
        </Link>
      </Container> */}
    </div>
  )
}
