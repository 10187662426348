import React from 'react'
import { Alert, Button, FloatingLabel, Form, Modal, Spinner } from 'react-bootstrap'
import CompaniesService from '../../services/companies/CompaniesService'
import userStore from '../../stores/userStore'
import { _getCompaniesData } from '../../utils/api/totaraApi'
import { useOutletContext } from 'react-router-dom'
import { errorType } from '../../definitions/errorType'


export default function ModalUpdateCompany(modalUpdateCompanyProps: any) {
 
  const {setCompaniesData, setShowUpdateSuccess} = useOutletContext<any>()
    const { showUpdateCompany, handleCloseUpdateCompany, selectedCompanyData, setSelectedCompanyData, setIsLoading } = modalUpdateCompanyProps
    const dataStore = userStore((state: any) => state)
    const [isLoadingCompany, setIsLoadingCompany] = React.useState<boolean>(false)
    const [isError, setIsError] = React.useState<errorType>({
      error: false,
      message: ''
    })
    const [companyData, setCompanyData] = React.useState<any>({
      name: '',
      slug: '',
      userApi: '',
      dateCreation: ''
    })
  
    const roleTab = ['user', 'shipper', 'admin', 'super_admin']
  
    const handleUpdateCompany = async (e: React.SyntheticEvent) => {
      e.preventDefault()
      setIsLoadingCompany(true)
      setIsError({
        error: false,
        message: ''
      })
      const company_id = selectedCompanyData.id_company
      try {
        const response = await CompaniesService.updateCompany(
          dataStore.token,
          selectedCompanyData,
          company_id
        )
        
        if (response.status === 200) {
          setIsLoadingCompany(false)
         
          handleCloseUpdateCompany()
          _getCompaniesData(dataStore.token, setCompaniesData, setIsLoading)
          setSelectedCompanyData({
            name: '',
            slug: '',
            userApi: '',
            dateCreation: '',
          })
          setShowUpdateSuccess(true)
        }
      } catch (error: any) {
        console.log(error)
        setIsLoadingCompany(false)
        const messageError = JSON.parse(error?.request?.response)?.data.id_company ? JSON.parse(error?.request?.response)?.data.id_company : error?.response?.data?.message
        setIsError({
          error: true,
          message: error?.response?.data?.message + " : " + messageError || error?.message || 'Une erreur est survenue'
        })
        
      }
    }

    // console.log(selectedCompanyData)
  
    return (
      <Modal show={showUpdateCompany} onHide={handleCloseUpdateCompany}>
        <Form onSubmit={handleUpdateCompany}>
          <Modal.Header className='border-bottom'>
            <Modal.Title>Modifier une compagnie</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FloatingLabel controlId='company_name' label='Nom' className='mb-3'>
              <Form.Control
                className='shadow border mb-3'
                name='name'
                type='text'
                autoComplete='on'
                placeholder='Nom'
                value={selectedCompanyData?.name}
                onChange={(e) => {
                  const name = e?.currentTarget?.value

                  setSelectedCompanyData((prevData: any) => ({
                    ...prevData,
                    name: name,
                  }))
                }}
                required
              />
            </FloatingLabel>
            <Alert show={isError.error} variant='danger'>
              <i className='ri-error-warning-line text-danger me-2 fs-4 '></i>{isError.message}
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                handleCloseUpdateCompany()
                setSelectedCompanyData({
                  name: '',
                  slug: '',
                  userApi: '',
                  dateCreation: '',
                })
              }}
            >
              Annuler
            </Button>
            <Button variant='primary' type='submit'>
            {isLoadingCompany ? (
              <>
                <Spinner variant='light' size='sm' /> Loading
              </>
            ) : (
              <>
                <i className='ri-community-line'></i> Valider
              </>
            )}
              
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }