import React from 'react'
import {
  Form,
  Table,
  Button,
  Container,
  Spinner,
  Alert,
  Badge,
  Pagination,
} from 'react-bootstrap'
import { ToastCurrentTrip, ToastSendedBrouillon } from '../../component/ui/Toast/Toastes'
import { DetailOrderModal, SearchPlanningModal } from '../../component/ui/Modal/Modals'
import userStore from '../../stores/userStore'
import { useNavigate } from 'react-router-dom'
import {
  _getOrdersData,
  _getOrdersData2,
  _tagStatus,
  _transformDataToNested,
} from '../../utils/functions'
import { Tag } from 'rsuite'
import { OrderType } from '../../definitions/OrderType'
import OrdersService from '../../services/orders/OrdersService'
import { debounce } from 'lodash';
import { previousDay } from 'date-fns'

export default function Order() {
  ///////////
  //store Data
  /////////////
  const dataStore = userStore((state: any) => state)
  const navigate = useNavigate()

  //////////////
  //State
  /////////////
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isFiltering, setIsFiltering] = React.useState<boolean>(false)
  const [errorOrderMessage, setErrorOrderMessage] = React.useState<{
    message: string
    isError: boolean
  }>({
    message: '',
    isError: false,
  })

  const [dataOrder, setDataOrder] = React.useState<any>()
  const [selectedOrder, setSelectedOrder] = React.useState<any>({})
  const [filteringData, setFilteringData] = React.useState<any>({
    date_creation: '',
    referenceHorsRevatua: '',
    destinataire_denomination: '',
    numeroVoyage: '',
    statut_revatua: '',
    ileArrivee: '',
  })

  const [currentPage, setCurrentPage] = React.useState(1);
const [totalPages, setTotalPages] = React.useState(1);

  const [ordersForConnaissement, setOrdersForConnaissement] = React.useState<any>([])
  const [totalOrder, setTotalOrder] = React.useState<number>(0)


  const [showA, setShowA] = React.useState<boolean>(false)
  const toggleShowA = () => setShowA(!showA)

  const [showBrouillon, setShowBrouillon] = React.useState<boolean>(false)
  const toggleShowBrouillon = () => setShowBrouillon(!showBrouillon)

  const [show, setShow] = React.useState(false)




  const handleClose = () => {
    setTotalOrder(0)
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const [showSearchPlanning, setShowSearchPlanning] = React.useState(false)

  const handleCloseSearchPlanning = () => setShowSearchPlanning(false)
  const handleShowSearchPlanning = () => {
    if (ordersForConnaissement.length > 0) {
      setShowSearchPlanning(true)
    } else {
      alert('Veuillez selectionner au moins une commande')
    }
  }


  React.useEffect(() => {
    if (!dataStore.token || dataStore.token === undefined) {
      navigate('/connexion')
    }
    _getOrdersData2(dataStore.token, currentPage, setDataOrder, setTotalPages, setIsLoading, setErrorOrderMessage)
  }, [])

  React.useEffect(() => {
  if(isFiltering){
    filteredOrder(dataStore.token, filteringData);
  }else{

    _getOrdersData2(dataStore.token, currentPage, setDataOrder, setTotalPages, setIsLoading, setErrorOrderMessage)
  }
  }, [currentPage])

  React.useEffect(() => {
    setTotalOrder(
      selectedOrder?.detailProduits?.reduce(
        (acc: number, cur: any) => (acc += cur?.nbColis * cur?.tarifUnitaire),
        0
      )
    )
  }, [selectedOrder])

  //Gère les commandes selectionnées
  const handleSelectOrders = (order: OrderType) => {
    if (ordersForConnaissement.includes(order)) {
      setOrdersForConnaissement(ordersForConnaissement?.filter((item: any) => item !== order))
    } else {
      setOrdersForConnaissement([...ordersForConnaissement, order])
    }
  }
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const validOrders = dataOrder?.filter((order: any) => {
        const isDifferentDestinataire =
          (ordersForConnaissement?.length > 0 &&
            ordersForConnaissement[0]?.destinataire?.denomination !==
              order?.destinataire?.denomination) ||
          (order?.statusRevatua !== 'A_PLANIFIER' && order?.statusRevatua !== 'BROUILLON')

        return !isDifferentDestinataire
      })

      if (validOrders?.length !== dataOrder?.length) {
        alert(
          'Vous devez sélectionner des commandes avec le même client et les status "A_PLANIFIER" ou "BROUILLON"'
        )
        setOrdersForConnaissement([])
      } else {
        setOrdersForConnaissement(validOrders)
      }
    } else {
      // Désélectionne toutes les commandes
      setOrdersForConnaissement([])
    }
  }


  const _handlefilteredOrder = (e: any) => {
    const { name, value } = e.currentTarget;
    const updatedFilteringData = {
      ...filteringData,
      [name]: value,
    };
    setFilteringData(updatedFilteringData);
    debouncedFilter(updatedFilteringData);
  };
  const handlefilteredOrder = (e: any) => {
    const { name, value } = e.currentTarget;
    const updatedFilteringData = {
      ...filteringData,
      [name]: value,
    };
    setFilteringData(updatedFilteringData);
    // debouncedFilter(updatedFilteringData);
  };

  const debouncedFilter = debounce((updatedFilteringData) => {
    filteredOrder(dataStore.token, updatedFilteringData);
  }, 900); // 300ms delay

  const filteredOrder = async (token: any, filteringData: any) => {
    // Filtrer les paramètres qui ne sont pas définis ou sont vides
    const filteredParams = Object.entries(filteringData)
      .filter(([key, value]) => value) // Garde les paires où la valeur est définie (non null, non undefined, non vide)
      .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`) // Encode chaque paramètre
      .join('&'); // Les concatène avec '&'


    try {
      const response = await OrdersService.filteredOrder(token, filteredParams, currentPage)
      // const response = await OrdersService.filteredOrder(token, filteringData)
      setTotalPages(response.data.last_page);  // Nombre total de pages
      
      console.log(response.data)
      if (response.data.data?.length > 0) {
        setDataOrder(_transformDataToNested(response.data.data))
        setIsFiltering(true)
      }
      if(response.data.data?.length === 0){
        setIsLoading(false)
        setIsFiltering(true)
        setDataOrder([{"destinataire": {"denomination":'Aucun résultat pour votre recherche'}}])  
      }
      
    } catch (error) {
      console.log(error)
      setIsFiltering(true)
    }
  }
  console.log(totalPages)


  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const searchPlanningProps = {
    ordersForConnaissement,
    setOrdersForConnaissement,
    handleCloseSearchPlanning,
    toggleShowA,
    toggleShowBrouillon,
    dataOrder,
    setDataOrder,
  }
  const toastCurrentTripProps = { showA, toggleShowA }
  const toastSendedBrouillonProps = { showBrouillon, toggleShowBrouillon }
  const SearchPlanningModalProps = {
    showSearchPlanning,
    handleCloseSearchPlanning,
    searchPlanningProps,
  }
  const detailOrderModalProps = {
    show,
    selectedOrder,
    handleClose,
    setSelectedOrder,
    dataOrder,
    setDataOrder,
    setErrorOrderMessage,
    setIsLoading,
  }
  
  // console.log(filteringData)
  console.log(dataOrder)

  return (
    <div className='p-3 pb-5 mb-5'>
      <h3 className='text-secondary'>Commande</h3>
  

      <Table striped hover responsive className=' border'>
        <thead className=''>
          <tr>
            <th className='p-1 p-sm-2'></th>

            <th className='responsive-font-small p-1 p-sm-2'>Date</th>
            <th className='responsive-font-small p-1 p-sm-2'>N°</th>
            <th style={{ width: '265px' }} className='responsive-font-small p-1 p-sm-2'>
              Client
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>N°voyage</th>
            <th className='responsive-font-small p-1 p-sm-2'>Status</th>
            <th className='responsive-font-small p-1 p-sm-2'>Arrivée</th>
            <th className='responsive-font-small p-1 p-sm-2'></th>
          </tr>
        </thead>
        <thead className=''>
          <tr>
            <th className='p-1 p-sm-2'>
              <Form.Check
                type='checkbox'
                id={`allCheck`}
                onChange={handleSelectAll}
                checked={ordersForConnaissement?.length === dataOrder?.length}
                required
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Date'
                name='date_creation'
                value={filteringData.date_creation || ''}
                onChange={handlefilteredOrder}
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='N°'
                name='referenceHorsRevatua'
                value={filteringData.referenceHorsRevatua}
                onChange={handlefilteredOrder}
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Client'
                name='destinataire_denomination'
                value={filteringData.destinataire_denomination || ''}
                onChange={handlefilteredOrder}
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='N°voyage'
                name='numeroVoyage'
                value={filteringData.numeroVoyage || ''}
                onChange={handlefilteredOrder}
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Status'
                name='statut_revatua'
                value={filteringData.statut_revatua || ''}
                onChange={handlefilteredOrder}
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Arrivée'
                name='ileArrivee'
                value={filteringData.ileArrivee || ''}
                onChange={handlefilteredOrder}
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              <Button onClick={() => filteredOrder(dataStore.token, filteringData)}>
                Valider
              </Button>
              {isFiltering && (
                <span
                className='pointer'
                  onClick={() => {
                    setFilteringData({
                      date_creation: '',
                      referenceHorsRevatua: '',
                      destinataire_denomination: '',
                      numeroVoyage: '',
                      statut_revatua: '',
                      ileArrivee: '',
                    })
                    _getOrdersData2(
                      dataStore.token,
                      currentPage,
                      setDataOrder,
                      setTotalPages,
                      setIsLoading,
                      setErrorOrderMessage
                    )
                    setIsFiltering(false)
                  }}
                >
                  <u>Réinitialiser</u>
                </span>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {(!errorOrderMessage.isError || !isLoading) &&
            dataOrder?.map((order: OrderType, indx: number) => {
              const isDifferentDestinataire =
                (ordersForConnaissement?.length > 0 &&
                  ordersForConnaissement[0]?.destinataire?.denomination !==
                    order?.destinataire?.denomination) ||
                (order?.statusRevatua !== 'A_PLANIFIER' &&
                  order?.statusRevatua !== 'BROUILLON')
              return (
                <tr key={indx}>
                  <td className='p-1 p-sm-2'>
                    {' '}
                    {order?.date_creation !== undefined && (
                      <Form.Check
                        type='checkbox'
                        id={`${order.id}`}
                        onChange={() => {
                          handleSelectOrders(order)
                        }}
                        checked={
                          ordersForConnaissement && ordersForConnaissement?.includes(order)
                        }
                        value={order?.referenceHorsRevatua}
                        disabled={isDifferentDestinataire}
                        required
                      />
                    )}
                  </td>

                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2 '
                  >
                    {order?.date_creation !== undefined && order?.date_creation}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2 '
                  >
                    <span className='truncate'>{order?.referenceHorsRevatua} </span>
                  </td>

                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  >
                    {order?.destinataire?.denomination}{' '}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  >
                    {order?.numeroVoyage}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='d-none d-sm-table-cell responsive-font-small pointer p-1 p-sm-2'
                  >
                    {' '}
                    <Tag size='sm' color={_tagStatus(order?.statusRevatua)}>
                      {order?.statusRevatua}
                    </Tag>
                  </td>
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='d-table-cell d-sm-none responsive-font-small pointer p-1 p-sm-2'
                  >
                    {order?.statusRevatua}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  >
                    {order?.ileArrivee}
                  </td>
                  <td
                    onClick={() => {
                      setSelectedOrder(order)
                      handleShow()
                    }}
                    className='responsive-font-small pointer p-1 p-sm-2'
                  ></td>
                </tr>
              )
            })}
        </tbody>
      </Table>
      <Pagination size="sm">
      <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {[...Array(totalPages)].map((_, i) => (
            <Pagination.Item
              key={i}
              active={i + 1 === currentPage}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />

      </Pagination>
      {isLoading && (
        <Container
          fluid
          className='bg-body-tertiary d-flex justify-content-center align-items-center vh-100 text-center'
        >
          <Spinner variant='primary' /> <span className='ms-3'>Loading...</span>
        </Container>
      )}
      {errorOrderMessage?.isError && (
        <Container fluid className=' d-flex justify-content-center pt-5 text-center'>
          <Alert
            variant='danger'
            className='d-flex  align-items-center py-2 py-sm-3 mt-5 responsive-font-small'
          >
            <i className='ri-error-warning-line fs-2 text-danger  me-3'></i>
            Oups une erreur est survenue : {errorOrderMessage?.message}
          </Alert>
        </Container>
      )}
      {ordersForConnaissement?.length > 0 && (
        <Badge
          bg='dark'
          className='m-auto p fab2 rounded-circle primary-colo'
          onClick={handleShowSearchPlanning}
        >
          {ordersForConnaissement?.length}
        </Badge>
      )}
      <Button
        variant='primary'
        className='m-auto fab rounded-pill primary-colo'
        onClick={handleShowSearchPlanning}
      >
        <i className='ri-sailboat-fill'></i> Planning
      </Button>

      <SearchPlanningModal SearchPlanningModalProps={SearchPlanningModalProps} />
      <DetailOrderModal detailOrderModalProps={detailOrderModalProps} />

      <ToastSendedBrouillon toastSendedBrouillonProps={toastSendedBrouillonProps} />
      <ToastCurrentTrip {...toastCurrentTripProps} />
    </div>
  )
}
