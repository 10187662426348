import React from 'react'
import { Dropdown, Image, Navbar } from 'react-bootstrap'
import tots from '../../styles/images/remora.png'
import CountDown from '../ui/CountDown'
import { Link } from 'react-router-dom'
import userStore from '../../stores/userStore'
import { Tag } from 'rsuite'

export default function Header() {

  const titleApp = process.env.REACT_APP_TITLE

  const authLogout = userStore((state: any) => state.authLogout)
  const dataStore = userStore((state: any) => state)

  const [isDarkMode, setIsDarkMode] = React.useState(false)

  React.useEffect(() => {
    if (isDarkMode) {
      document.body.setAttribute('data-bs-theme', 'dark')
    } else {
      document.body.setAttribute('data-bs-theme', 'light')
    }

    return () => {
      document.body.removeAttribute('data-bs-theme')
    }
  }, [isDarkMode])
  
  return (
    <header className='sticky-top border-bottom '>
      <Navbar className='bg-body-tertiary px-3 py-0 py-md-2'>
        <Navbar.Brand className='d-flex align-items-center'>
          <Image src={tots} width={50} alt='logo' className='me-2' />
          {/* <Tag size="lg"  className='border border-secondary rounded-pill px-3'>
           {dataStore?.company && dataStore?.company?.length > 1 ? "LOGITECH" : dataStore?.company?.length === 1 ? dataStore?.company[0]?.name : "ALL COMPANY"}
           </Tag> */}
        </Navbar.Brand>
        <div className='d-flex ms-auto'>
          {/* <div className='d-none d-sm-flex align-items-center me-4 '>
            {/* <CountDown /> 
          </div> */}
          <div className='d-flex align-items-center me-4'>{dataStore.name}</div>
          <div>
            <Dropdown>
              <Dropdown.Toggle
                variant='transparent'
                id='dropdown-basic'
                className='border-0 no-chevron'
              >
                <i className='ri-more-2-line fs-4'></i>
              </Dropdown.Toggle>
              <Dropdown.Menu align='end'>
                <Dropdown.Item className='d-block d-md-none'>
                  <Link className='text-decoration-none text-secondary' to='/compagnies'>
                    <i className='ri-community-line fs-5'></i> Compagnies
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className='d-block d-md-none'>
                  <Link className='text-decoration-none text-secondary' to='/utilisateurs'>
                    <i className='ri-user-line fs-5'></i> utilisateurs
                  </Link>
                </Dropdown.Item>
                {/* <Dropdown.Item className='d-block d-md-none'>
                  <Link className='text-decoration-none text-secondary' to='/permissions'>
                    <i className='ri-list-check-3 fs-5'></i> Permissions
                  </Link>
                </Dropdown.Item> */}
                <Dropdown.Item
                  className='text-secondary'
                  onClick={() => setIsDarkMode(!isDarkMode)}
                >
                  <i className={`ri-${!isDarkMode ? 'moon' : 'sun'}-line fs-4`}></i> Thème
                </Dropdown.Item>
                <Dropdown.Item onClick={authLogout}>
                  <Link className='text-decoration-none text-secondary' to='/connexion'>
                    <i className='ri-logout-box-r-line fs-5'></i> Déconnexion
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Navbar>
    </header>
  )
}
