import axios from 'axios'

const API_URL = process.env.REACT_APP_END_POINT
class VersionService {
  getVersion() {
    return axios.get(API_URL + 'version')
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new VersionService()
