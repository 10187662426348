import IlesService from "../../services/IlesService"
import TrajetsService from "../../services/TrajetsService"

export const _getIslandByName = async (name: string, setListIsland: any) => {
    try {
      if(name.length > 1){
      const response = await IlesService.getIslandByName(name)
      if (response.data.length > 0) {
        setListIsland(response.data)
      }
    }
    } catch (error) {
      console.log(error)
    }
  }

export  const _getTrajetByIslandId = async (
    idIle: string | null,
    page: number,
    limit: number,
    dateDebut: string,
    dateFin: string,
    nameNavire: string,
    setTrajetData: any,
    setSearchIslandName: any
  ) => {
    try {
      const response = await TrajetsService.getTrajetByIslandId(
        idIle,
        page,
        limit,
        dateDebut,
        dateFin
      )
      const filteredData = response.data?.content.filter(
        (navire: any) => navire.nomNavire === nameNavire
      )
      console.log(response.data)
      if (filteredData?.length > 0) {
        setTrajetData(filteredData)
        setSearchIslandName('')
      } else {
        setTrajetData(response.data?.content)
        setSearchIslandName('')
      }
    } catch (error) {
      console.log(error)
    }
  }

export const _getPlanningOfNavire = async (
    idNavire: string | null,
    page: string,
    limit: string,
    dateDebut: string,
    dateFin: string,
    setSelectedTrajet: any,
    setTrajetData: any,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setIsLoading(true)
    try {
      const response = await TrajetsService.getTrajetByNavireId(
        idNavire,
        page,
        limit,
        dateDebut,
        dateFin
      )

      if(response.data.content?.length === 0 ){
        setIsLoading(false)
        alert("Pas de planning")
        setSelectedTrajet(undefined)
        setTrajetData(undefined)

      }
      else if (response.data.content.length > 0) {
        setSelectedTrajet(undefined)
        setTrajetData(response.data.content)
    setIsLoading(false)

      }
    } catch (error) {
      console.log(error)
    setIsLoading(false)

    }
  }

export const _getPlanningMooz = async (
  idNavire: number,
  page: string,
  limit: string,
  dateDebut: string,
  dateFin: string,
  setSelectedTrajet: any,
  setTrajetData: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true)
  try {

    const response = await TrajetsService.getPlanningMoorea(idNavire, dateDebut, dateFin, 29)

    if (response.data?.length === 0) {
      alert('Pas de planning')
      setSelectedTrajet(undefined)
      setTrajetData(undefined)
      setIsLoading(false)
    } else if (response.data.length > 0) {
      setSelectedTrajet(undefined)
      setTrajetData(response.data)
      setIsLoading(false)
    }
  } catch (error) {
    console.log(error)
    setIsLoading(false)
  }
}
