import React from 'react'
import { Alert, Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import UserService from '../../services/user/UserService'
import userStore from '../../stores/userStore'
import { _getUsersData } from '../../utils/api/totaraApi'
import CompaniesService from '../../services/companies/CompaniesService'
import { useOutletContext } from 'react-router-dom'
import { CheckPicker } from 'rsuite'

export default function ModalUpdateUser(modalUpdateUserProps: any) {
  const { setShowUpdateSuccess} = useOutletContext<any>()

  const {
    showUpdateUser,
    handleCloseUpdateUser,
    selectedUserData,
    setSelectedUserData,
    setUserData,
    setIsLoading
  } = modalUpdateUserProps

  const dataStore = userStore((state: any) => state)
  const [companies, setCompanies] = React.useState<any>([])
  const [updatedUserData, setUpdatedUserData] = React.useState<any>({
    "name":"",
    "email":"",
    "role":"",
    "companies":[],
 
  })
  const [errorUpdateMessage, setErrorUpdateMessage] = React.useState<any>({
    message: '',
    isError: false,
  })

  const roleTab = ['user', 'transporteur', 'comptable', 'admin', 'super_admin']

  

  React.useEffect(() => {
    const newCompaniesData = selectedUserData?.companies?.map((companies: any) => {
      return(
        companies?.id_company
      )
    })
    setSelectedUserData((prevData: any) => ({
      ...prevData,
      role: selectedUserData?.role && selectedUserData?.role[0]?.name,
      companies: newCompaniesData
    }))
    
      
  }, [showUpdateUser])


  React.useEffect(() => {

    setUpdatedUserData(selectedUserData)
    
    getCompaniesData()
  }, [selectedUserData])

  const handleUpdateUser = async (e: any) => {
    e.preventDefault()

    const userId = selectedUserData.id
    

if(updatedUserData?.companies?.length > 1 && updatedUserData.role !== "transporteur"){
  alert('Seul le rôle transporteur peut être associé à plusieurs compâgnies')
}else if(updatedUserData?.companies.includes(15) && updatedUserData.role === "transporteur"){
  alert('La compagnie CEDIS ne peut être associé au rôle transporteur')

}
 else{

    const data =
      selectedUserData.email === updatedUserData.email
        ? {
            name: updatedUserData?.name,
            role: updatedUserData?.role,
            companies: updatedUserData?.companies,
          }
        : {
            name: updatedUserData.name,
            email: updatedUserData.email,
            role: updatedUserData.role,
            companies: updatedUserData?.companies,
          }

    try {
      const response = await UserService.updateUsers(dataStore.token, data, userId)

      if (response.status === 200) {
        handleCloseUpdateUser()
        _getUsersData(dataStore.token, setUserData, setIsLoading)
        setShowUpdateSuccess(true)
        setSelectedUserData({
          id: null,
        })
        setErrorUpdateMessage({
          message: "",
          isError: false,
        })
      }
    } catch (error: any) {
      console.log(error)
      setErrorUpdateMessage({
        message: error?.message,
        isError: true,
      })
    }
 }

  }

  const getCompaniesData = async () => {
    try {
      let page = 1
      let allCompanies: any = []
      let hasMorePages = true

      while (hasMorePages) {
        const response = await CompaniesService.getCompanies(dataStore.token, page)
        allCompanies = [...allCompanies, ...response.data.data]
        allCompanies = allCompanies.sort((a: any, b: any) => a.name.localeCompare(b.name))

        if (response.data.meta.current_page < response.data.meta.last_page) {
          page++
        } else {
          hasMorePages = false
        }
      }
      setCompanies(allCompanies)
    } catch (error) {
      console.log(error)
    }
  }

  const data = companies.map((item: any) => ({ label: item.name, value: item.id_company}))

 
  return (
    <Modal show={showUpdateUser} onHide={handleCloseUpdateUser}>
      <Form onSubmit={handleUpdateUser}>
        <Modal.Header className='border-bottom'>
          <Modal.Title>Modifier un utilisateur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId='user_name' label='Nom' className='mb-3'>
            <Form.Control
              className='border border-1 border-secondary'
              name='lastName'
              type='text'
              autoComplete='on'
              placeholder='Nom'
              value={updatedUserData?.name || ''}
              onChange={(e) => {
                const lastname = e?.currentTarget?.value
                setUpdatedUserData((prevData: any) => ({
                  ...prevData,
                  name: lastname,
                }))
              }}
              required
            />
          </FloatingLabel>
          <FloatingLabel controlId='user_email' label='Email' className='mb-3'>
            <Form.Control
              className='border border-1 border-secondary'
              name='email'
              type='email'
              autoComplete='on'
              placeholder='Email'
              value={updatedUserData?.email || ''}
              onChange={(e) => {
                const email = e?.currentTarget?.value

                setUpdatedUserData((prevData: any) => ({
                  ...prevData,
                  email: email,
                }))
              }}
            />
          </FloatingLabel>
          <FloatingLabel controlId='user_role' label='Rôle' className='mb-3'>
            <Form.Select
              value={
                Array.isArray(updatedUserData?.role)
                  ? selectedUserData?.role && selectedUserData?.role[0]?.name
                  : updatedUserData?.role || ''
              }
              onChange={(e) => {
                const userRole = e?.currentTarget?.value
                setUpdatedUserData((prevData: any) => ({
                  ...prevData,
                  role: userRole,
                }))
              }}
              aria-label='zone'
              className='border border-1 border-secondary my-2 text-ui-second '
              required
            >
              <option value='' className='role-option'>
                Choisir un rôle
              </option>
              {roleTab?.map((role: any, index: any) => (
                <option key={index} value={role}>
                  {role}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>
          {/* <FloatingLabel controlId='user_company' label='Compagnie' className='mb-3'>
            <Form.Select
              value={(updatedUserData?.companies && updatedUserData?.companies[0]?.id_company) || ''}
              onChange={(e) => {
                // const id = e?.currentTarget?.value
                const id = parseInt(e?.currentTarget?.value)
                console.log(id)
                const company = companies?.filter(
                  (company: any) => company?.id_company === id
                )[0]
                setUpdatedUserData((prevData: any) => ({
                  ...prevData,
                  companies: company,
                }))
                // }
              }}
              aria-label='zone'
              className='border border-1 border-secondary my-2 text-ui-second '
              required
            >
              <option value='' className='text-ui-second'>
                Choisir une compagnie
              </option>
              {companies?.map((company: any, index: any) => (
                <option key={index} value={company.id_company}>
                  {company.name}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel> */}
            <CheckPicker
            size="lg"
            label="Compagnie"
              name='companies'
              className='text-dark'
              data={data}
              searchable={false}
              style={{ width: "100%" }}
              value={updatedUserData?.companies || []}
              onChange={(value: number[], e: any) => {
                const companies: number[] = value
                setUpdatedUserData((prevData: any) => ({
                  ...prevData,
                  companies: companies,
                }))
                
              }}
              disabledItemValues={updatedUserData?.role === "transporteur" ? [15] : []}
            />

          <Alert variant='danger' show={errorUpdateMessage.isError} className='mt-3'>
            {errorUpdateMessage.message}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              handleCloseUpdateUser()
              setSelectedUserData({})
              setUpdatedUserData({})
            }}
          >
            Annuler
          </Button>
          <Button variant='primary' type='submit'>
            Valider
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
