import axios from "axios";

const API_URL = process.env.REACT_APP_END_POINT_TEST
class ConnaissementService {
  postBrouillonConnaissement(token: string, data: any) {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/brouillons`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: data,
    }
    return axios.request(config)
  }

  getConnaissement(token: string) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/demandes`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }
  updateConnaissement(token: string, data: any, id: number) {
    const config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/connaissements/${id}/changereta`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: data,
    }
    return axios.request(config)
  }
  getIsland(token: string, id: number) {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${API_URL}/api/v1/iles/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    return axios.request(config)
  }
}

 
// eslint-disable-next-line import/no-anonymous-default-export
export default new ConnaissementService();
