import React from 'react'
import { Button, Col, Container, Dropdown, Form, InputGroup, Modal, Row, Table } from 'react-bootstrap'
import dataConnaissement from '../../data/connaissements/connaissement.json'
import { DetailConnaissementModal } from '../../component/ui/Modal/Modals'
import { Tag } from 'rsuite'
import { _tagStatus } from '../../utils/functions'
import ConnaissementService from '../../services/connaissements/ConnaissementServices'
import userStore from '../../stores/userStore'
import AuthService from '../../services/Auth/AuthService'
import { QRCode } from 'antd'


export default function BillOfLading() {
  const dataStore = userStore((state: any) => state)
  const [connaissementData, setConnaissementData] = React.useState<any>([])
  const [selectedConnaissement, setSelectedConnaissement] = React.useState<any>({})
  const [filteringData, setFilteringData] = React.useState<any>({
    numero: '',
    destinataire_denomination: '',
    nomNavire: '',
    numeroVoyage: '',
    statut_revatua: '',
    dateDepart: '',
    ileArrivee: '',
    dateArrivee: '',
  })

  const [show, setShow] = React.useState(false)

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = () => setShow(true)

  const [showQrcode, setShowQrcode] = React.useState(false)

  const handleCloseQrcode = () => {
    setShowQrcode(false)
  }
  const handleShowQrcode = () => setShowQrcode(true)

  React.useEffect(() => {
    setConnaissementData(dataConnaissement?.content)
    // authRevatua()
    // connaissementDataTable()
    // getIle()
  }, [])

  const handleFilterConnaissement = (event: any) => {
    const value = event.target.value;
    if(value.length > 2){
    const filteredData = connaissementData.filter((item: any) => {
      return (
      item.destinataire.denomination.toLowerCase().includes(value.toLowerCase())
     || item.numero.toLowerCase().includes(value.toLowerCase())
     || item.dernierEtatOfficialise?.evenementConnaissement.toLowerCase().includes(value.toLowerCase())
     || item.ileArrivee.nom.toLowerCase().includes(value.toLowerCase())
     || item.voyage?.dateDepart.toLowerCase().includes(value.toLowerCase())
     || item.voyage?.nomNavire.toLowerCase().includes(value.toLowerCase())
     || item.voyage?.periple[0].dateArrivee.toLowerCase().includes(value.toLowerCase())
      )
    })
    
   setConnaissementData(filteredData)
  }
  if(value.length === 0){
    setConnaissementData(dataConnaissement.content)
    }
  }

  const authRevatua = async() => {


try {
  const response = await AuthService.authRevatua()
  console.log(response)
}catch(error){
  console.log(error)
}

  }
  const connaissementDataTable = async () => {
    try {
      const response = await ConnaissementService.getConnaissement(dataStore.access_token)
      console.log(response)
      setConnaissementData(response.data.data?.content)
    } catch (error) {
      console.log(error)
    }
  }

  const getIle =async() => {
    try {
      const response = await ConnaissementService.getIsland(dataStore.access_token, 29)
      console.log(response)
    }catch(error){
      console.log(error)
    }
  }

  const detailOrderModalProps = { show, selectedConnaissement, handleClose }
  const qrCodeModalProps = { show, selectedConnaissement, handleClose }

  console.log(selectedConnaissement)
  console.log(dataConnaissement?.content)

  return (
    <div className='p-1 p-sm-3'>
      <h3 className='text-secondary'>Connaissements</h3>
      <div>
        <Form.Group className='mb-3' controlId='searchBar'>
          <InputGroup className=''>
            <InputGroup.Text id='basic-addon1' className='bg-secondary border'>
              <i className='ri-search-line text-light'></i>
            </InputGroup.Text>
            <Form.Control
              className='border'
              type='text'
              autoComplete='on'
              placeholder='Recherche'
              onChange={handleFilterConnaissement}
            />
          </InputGroup>
        </Form.Group>
      </div>

      <Table striped hover responsive className='responsive-font-small border'>
        <thead className=''>
          <tr>
            <th></th>
            <th>N°Connaiss...</th>
            <th>Client</th>
            <th>Navire</th>
            <th>Status</th>
            <th>Date départ</th>
            <th>Ile d'arrivée</th>
            <th>Date Arrivée</th>
            <th>Action</th>
          </tr>
        </thead>
        {/* <thead className=''>
          <tr>
            <th className='p-1 p-sm-2'>
              <Form.Check
                type='checkbox'
                id={`allCheck`}
              
                required
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='N°Connaisse...'
                name='date_creation'
           
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Client'
                name='client'
         
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Navire'
                name='nomNavire'
             
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Status'
                name='statut_revatua'
              
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Date départ'
                name='date_depart'
             
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              {' '}
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder="Ile d'arrivée"
                name='ileArrivee'
   
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              <Form.Control
                className=' border'
                type='text'
                autoComplete='on'
                placeholder='Date Arrivée'
                name='dateArrivee'
           
              />
            </th>
            <th className='responsive-font-small p-1 p-sm-2'>
              <Button
              >
                Valider
              </Button>
              
            </th>
          </tr>
        </thead> */}
        <tbody>
          {connaissementData?.map((connaissement: any, indx: number) => (
            <tr key={indx}>
              <td className='p-1 p-sm-2'>
                {' '}
                {/* {order?.date_creation !== undefined && ( */}
                <Form.Check
                  type='checkbox'
                  // id={`${order.id}`}
                  // onChange={() => {
                  //   handleSelectOrders(order)
                  // }}
                  // checked={
                  //   ordersForConnaissement && ordersForConnaissement?.includes(order)
                  // }
                  // value={order?.referenceHorsRevatua}
                  // disabled={isDifferentDestinataire}
                  required
                />
                {/* )} */}
              </td>
              <td
                onClick={() => {
                  setSelectedConnaissement(connaissement)
                  handleShow()
                }}
                className='pointer p-1 pe-0 p-md-2'
              >
                {connaissement?.numero}{' '}
              </td>
              <td
                onClick={() => {
                  setSelectedConnaissement(connaissement)
                  handleShow()
                }}
                className='pointer p-1 pe-0 p-md-2'
              >
                {connaissement?.destinataire?.denomination}{' '}
              </td>
              <td
                onClick={() => {
                  setSelectedConnaissement(connaissement)
                  handleShow()
                }}
                className='pointer p-1 pe-0 p-md-2'
              >
                {connaissement?.voyage?.nomNavire}
              </td>
              <td
                onClick={() => {
                  setSelectedConnaissement(connaissement)
                  handleShow()
                }}
                className='pointer p-1 pe-0 p-md-2 responsive-font-small'
              >
                <Tag
                  className='responsive-font-small'
                  size='sm'
                  color={_tagStatus(connaissement?.dernierEtat?.evenementConnaissement)}
                >
                  {connaissement?.dernierEtat?.evenementConnaissement}
                  {/* {connaissement?.dernierEtatOfficialise?.evenementConnaissement} */}
                </Tag>
              </td>
              <td
                onClick={() => {
                  setSelectedConnaissement(connaissement)
                  handleShow()
                }}
                className='pointer p-1 pe-0 p-md-2'
              >
                {connaissement?.voyage?.dateDepart}
              </td>
              <td
                onClick={() => {
                  setSelectedConnaissement(connaissement)
                  handleShow()
                }}
                className='pointer p-1 pe-0 p-md-2'
              >
                {connaissement?.ileArrivee?.nom}
              </td>
              <td
                onClick={() => {
                  setSelectedConnaissement(connaissement)
                  handleShow()
                }}
                className='pointer p-1 pe-0 p-md-2'
              >
                {connaissement?.voyage?.periple[0].dateArrivee}
              </td>
              <td className='pointer p-1 pe-0 p-md-2'>
                <Dropdown>
                  <Dropdown.Toggle
                    variant='transparent'
                    id='dropdown-basic'
                    className='border-0 no-chevron'
                  >
                    <b>
                      {' '}
                      <i className='ri-more-2-line'></i>
                    </b>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align='end'>
                    <Dropdown.Item
                      className='d-flex align-items-center '
                      onClick={() => {
                        setSelectedConnaissement(connaissement)
                        handleShowQrcode()
                      }}
                    >
                      <i className='ri-qr-code-line fs-4 me-2'></i> Qrcode
                    </Dropdown.Item>
                    <Dropdown.Item
                      className='d-flex align-items-center '
                      onClick={() => {
                        // setSelectedUserData(user)
                        // handleShowUpdateUser()
                      }}
                    >
                      <i className='ri-download-2-line fs-4 me-2'></i> Télécharger
                    </Dropdown.Item>
                    <Dropdown.Item
                      className='d-flex align-items-center '
                      // onClick={() => {
                      //   // setSelectedUserData(user)
                      //   // handleShowUpdateUser()
                      // }}
                    >
                      <i className='ri-close-circle-line fs-4 me-2 text-danger'></i> Supprimer
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <DetailConnaissementModal detailOrderModalProps={detailOrderModalProps} />

      <Modal show={showQrcode} onHide={handleCloseQrcode}>
        <Modal.Header>
          <Container fluid>
            <Row>
              <Col xs={0} md={2} className='m-auto text-start responsive-font-small'></Col>
              <Col xs={12} md={8} className='m-auto responsive-font-medium text-center'>
                <div>{selectedConnaissement?.voyage?.nomNavire}</div>
                <div className='font-75'>
                  BP {selectedConnaissement?.armateur?.boitePostale}{' '}
                  {selectedConnaissement?.armateur?.commune?.codePostal}{' '}
                  {selectedConnaissement?.armateur?.commune?.nom} <strong>Tél : </strong>{' '}
                  {selectedConnaissement?.armateur?.telephone}
                </div>
              </Col>
              <Col
                xs={12}
                md={2}
                className='d-flex justify-content-center py-2 py-md-0  text-md-end responsive-font-small'
              ></Col>
            </Row>
            <Row>
              <Col xs={4} className='m-auto text-start responsive-font-small'></Col>
              <Col xs={4} className='m-auto responsive-font-medium'>
                <strong>CONNAISSEMENT</strong>
              </Col>
              <Col xs={4} className='m-auto text-end responsive-font-small'></Col>
            </Row>
            <Row className='responsive-font-small mb-3'>
              <Col xs={4} className='m-auto text-start responsive-font-small'>
                Date :{' '}
                {new Date(selectedConnaissement.voyage?.dateDepart).toLocaleDateString(
                  'fr-FR',
                  {
                    timeZone: 'UTC',
                  }
                )}{' '}
                {selectedConnaissement.voyage?.heureDepart?.hour && (
                  <span>
                    {selectedConnaissement.voyage?.heureDepart?.hour} :{' '}
                    {selectedConnaissement.voyage?.heureDepart?.minute}
                  </span>
                )}
              </Col>
              <Col xs={4} className='m-auto responsive-font-medium text-center'>
                <Tag
                  className='responsive-font-small'
                  size='sm'
                  color={_tagStatus(
                    selectedConnaissement?.dernierEtat?.evenementConnaissement
                  )}
                >
                  {selectedConnaissement?.dernierEtat?.evenementConnaissement}
                  {/* {connaissement?.dernierEtatOfficialise?.evenementConnaissement} */}
                </Tag>
              </Col>
              <Col xs={4} className='m-auto text-end responsive-font-small font-85'>
                N°: {selectedConnaissement?.numero}
              </Col>
            </Row>
            {selectedConnaissement?.dernierEtat?.motif && (
              <Row className='responsive-font-small mb-3'>
                <Col xs={12} className='m-auto text-start responsive-font-small text-center'>
                  {selectedConnaissement?.dernierEtat?.motif}
                </Col>
              </Row>
            )}
          </Container>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-center'>
          <QRCode
            value={selectedConnaissement?.numero}
            size={320}
            bordered={true}
            bgColor={'#ffffff'}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className='w-100' variant='primary' onClick={handleCloseQrcode}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
